import { Link, NavLink } from "@remix-run/react";
import Instagram from "./instagram_logo.svg";
import Facebook from "./facebook_logo.svg";
import Strava from "./strava_logo.svg";

export function navClass({ isActive }: { isActive: boolean }) {
  return `block flex-shrink-0${isActive ? " font-medium" : ""}`;
}

export function NavigationLinks({
  gap,
  center,
}: {
  gap: number;
  center: boolean;
}) {
  return (
    <>
      <div
        className={`flex gap-x-${gap} flex-wrap${center ? " justify-center" : ""}`}
      >
        <NavLink to="/calendar" className={navClass}>
          Calendar
        </NavLink>
        <NavLink to="/trails" className={navClass}>
          Trail Beta
        </NavLink>
        <NavLink to="/volunteer" className={navClass}>
          Volunteer
        </NavLink>
      </div>
      <div className={`flex gap-x-4 flex-shrink-0`}>
        <Link
          to="https://www.strava.com/clubs/taos"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Strava} alt="Strava Group" height={20} width={14} />
        </Link>
        <Link
          to="https://www.instagram.com/taostrailrunners/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Instagram} alt="Instagram" height={20} width={20} />
        </Link>
        <Link
          to="https://www.facebook.com/groups/564770456924017/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Facebook} alt="Facebook" height={20} width={20} />
        </Link>
      </div>
    </>
  );
}
